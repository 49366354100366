import SearchIcon from '@mui/icons-material/Search'
import { Grid, InputBase } from '@mui/material'
import SelectInput, { SelectOption } from '../../../components/inputs/select-input/select-input'

interface IProps<T> {
  searchStateLabel: string
  searchState: string | undefined
  handleSearch(value: string): void
  selectedOption?: T | undefined
  options?: SelectOption<T>[]
  handleChange(selectedValue: T): void
}

export function SearchAndFilter<T>({
  searchStateLabel,
  searchState,
  handleSearch,
  selectedOption,
  options,
  handleChange,
}: Readonly<IProps<T>>): React.JSX.Element | null {
  return (
    <Grid
      container
      display='flex'
      justifyContent='space-between'
      alignItems='flex-start'
      flexDirection='row'
      rowGap={1}
      width='100%'
      sx={{ py: 1 }}>
      <Grid
        item
        xs={5}
        sx={{
          flex: 3,
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#ffffff',
          borderRadius: 3,
        }}>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          value={searchState}
          placeholder={searchStateLabel}
          inputProps={{ 'aria-label': 'search' }}
          endAdornment={<SearchIcon sx={{ margin: '0 8px' }} />}
          onChange={(e: { target: { value: any } }) => {
            handleSearch(e.target.value)
          }}
        />
      </Grid>

      {options && (
        <Grid item xs={2} display='flex' flexDirection='row' alignItems='center'>
          <SelectInput
            id='rseeRoleProject'
            label='Rôle'
            selectedOption={selectedOption}
            options={options}
            mode='direct'
            handleChange={handleChange}
            isBeemShot
            size='small'
          />
        </Grid>
      )}
    </Grid>
  )
}
