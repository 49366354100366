import React from 'react'
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom'
import { GoogleReCaptchaContextProvider } from './app/components/captcha/google-recaptcha-context'
import FullScreenViewer from './app/components/ifc-displayer/fullscreen-viewer'
import Layout from './app/components/layout/layout'
import LayoutAdmin from './app/components/layout/layoutAdmin'
import RequireAuth from './app/components/redirect/require-auth'
import RequireAuthUltimate from './app/components/redirect/require-auth-ultimate'
import RequireNoAuth from './app/components/redirect/require-no-auth'
import RequireOrganization from './app/components/redirect/require-organization'
import { adminPagesUrl, pagesUrl } from './app/core/appConstants'
import RseeDocumentContextProvider from './app/core/context/beem-pilot/rsee/rsee-document-context'
import { RseeProjectContextProvider } from './app/core/context/beem-pilot/rsee/rsee-project-context'
import BimModelContextProvider from './app/core/context/bim-model/bim-model-context'
import BimModelFileContextProvider from './app/core/context/bim-model/bim-model-file-context'
import { BimModelImportContextProvider } from './app/core/context/bim-model/bim-model-import-context'
import CalculationCarbonAdditionalDataProvider from './app/core/context/calculation/calculation-carbon-additional-data'
import CalculationCarbonExtractedDataProvider from './app/core/context/calculation/calculation-carbon-extracted-data'
import CalculationCarbonFlateRateProvider from './app/core/context/calculation/calculation-carbon-flate-rate-data'
import CalculationCarbonInformationProjectContextProvider from './app/core/context/calculation/calculation-carbon-information-project'
import CalculationCarbonMaterialFilterContextProvider from './app/core/context/calculation/calculation-carbon-material-filter-context'
import CalculationContextProvider from './app/core/context/calculation/calculation-context'
import CodeAcvCompletionContextProvider from './app/core/context/code-acv/code-acv-completion-context'
import CodeCustomContextProvider from './app/core/context/code-acv/code-custom-context'
import CodesCompletionsContextProvider from './app/core/context/code-acv/codes-completions-context'
import CodesExtraitsListContextProvider from './app/core/context/code-acv/codes-extraits-list-context'
import IniesRecordContextProvider from './app/core/context/inies-context'
import LotContextProvider from './app/core/context/lot-context'
import MaterialLibContextProvider from './app/core/context/material/material-lib-context'
import MaterialLibDeclarationTypeContextProvider from './app/core/context/material/material-lib-declaration-type-context'
import MaterialLibFilterContextProvider from './app/core/context/material/material-lib-filter-context'
import MaterialLibOrganizationIndicatorContextProvider from './app/core/context/material/material-lib-organization-indicator-context'
import MaterialLibProjectIndicatorContextProvider from './app/core/context/material/material-lib-project-indicator-context'
import MaterialLibSearchContextProvider from './app/core/context/material/material-lib-search-context'
import AdminOrganizationContextProvider from './app/core/context/organization/admin-organization-context'
import DomainNameValidatorContextProvider from './app/core/context/organization/domain-name-validator-context'
import OrganizationsListContextProvider from './app/core/context/organization/organization-list-context'
import ProjectMaterialContextProvider from './app/core/context/project-material-context'
import { ProjectContextProvider } from './app/core/context/project/project-context'
import ProjectTypeContextProvider from './app/core/context/project/project-type-context'
import SelectedPhaseProvider from './app/core/context/selected-phase-context'
import TutorialEnabledContextProvider from './app/core/context/tutoriel-enabled-context'
import ProjectRoleContextProvider from './app/core/context/user/project-role-context'
import RseeProjectRoleContextProvider from './app/core/context/user/rsee-project-role-context'
import VariantContextProvider from './app/core/context/variant-context'
import { getEnvProperty, PROPERTIES } from './app/core/services/environment-service'
import AccountPage from './app/pages/account-page/account-page'
import { adminMenu } from './app/pages/admin-page/admin-menu'
import BimProjectOrganizationPage from './app/pages/admin-page/organization/bim-project-organization-page'
import OrganizationList from './app/pages/admin-page/organization/organization-list'
import OrganizationPage from './app/pages/admin-page/organization/organization-page'
import RseeProjectOrganizationPage from './app/pages/admin-page/organization/rsee-project-organization-page'
import UserTableOrganizationPage from './app/pages/admin-page/organization/user-table-organization-page'
import AdminStatistics from './app/pages/admin-page/statistics/admin-statistics'
import TestUsersList from './app/pages/admin-page/tests-users/test-users-list'
import AdminInviteUserForm from './app/pages/admin-page/user/admin-invite-user-form'
import AdminUpdateUserForm from './app/pages/admin-page/user/admin-update-user-form'
import ConfirmPage from './app/pages/authentication/confirm-page/confirm-page'
import ForgotPasswordPage from './app/pages/authentication/forgot-password-page/forgot-password-page'
import SignInPage from './app/pages/authentication/sign-in-page/sign-in-page'
import SignUpPage from './app/pages/authentication/sign-up-page/sign-up-page'
import SignUpTestUserPage from './app/pages/authentication/sign-up-test-user-page/sign-up-test-user-page'
import BeemGuideExcelAcceptPage from './app/pages/beem-guide-excel-page/beem-guide-excel-accept-page'
import BeemGuideExcelPage from './app/pages/beem-guide-excel-page/beem-guide-excel-page'
import { BimModelExtensionPage } from './app/pages/bim-model-extension/BimModelExtensionPage'
import { KairnialDashboardPage } from './app/pages/kairnial/KairnialDashboardPage'
import MyOrganization from './app/pages/my-organization-page/my-organization'
import UserInvitationPage from './app/pages/my-organization-page/user-invite-page/user-invitation-page'
import UserUpdatePage from './app/pages/my-organization-page/user-update-page/user-update-page'
import { NoMatch } from './app/pages/no-match/no-match'
import OrganizationDashboardPage from './app/pages/organization-dashboard-page/OrganizationDashboardPage'
import OrganizationMaterialLibPage from './app/pages/organization-material-page/organization-material-lib-page'
import { BimModelUploadPage } from './app/pages/project-page/bim-model/bim-model-upload-page/bim-model-upload-page'
import TutorialPage from './app/pages/project-page/bim-model/tutorial-page/tutorial-page'
import GeneralInformationPage from './app/pages/project-page/calculation'
import { AdditionalCodePage } from './app/pages/project-page/calculation/additional-code-page/additional-code-page'
import { ExtractedCodePage } from './app/pages/project-page/calculation/extracted-code-page/extracted-code-page'
import FlateRatesPage from './app/pages/project-page/calculation/flate-rates-page/flate-rates-page'
import WorksitePage from './app/pages/project-page/calculation/worksite-page'
import ProjectControlBoard from './app/pages/project-page/control-board'
import ProjectPhaseStepper from './app/pages/project-page/control-board/project-phase-stepper'
import DashboardsPage from './app/pages/project-page/dashboard/dashboard-page'
import { ProjectHomePage } from './app/pages/project-page/home-page/project-home-page'
import ProjectForm from './app/pages/project-page/project-info/project-form'
import ProjectMaterialLibPage from './app/pages/project-page/project-material-lib/project-material-lib-page'
import RseeDocumentDashboard from './app/pages/project-page/rsee/rsee-dashboard/rsee-document-dashboard'
import RseeDocumentImportLog from './app/pages/project-page/rsee/rsee-document-import-log/rsee-document-import-log'
import RseeDocumentListPage from './app/pages/project-page/rsee/rsee-document-list/rsee-document-list-page'
import RseeGroupPage from './app/pages/project-page/rsee/rsee-group-page/rsee-group-page'
import { RseeImportForm } from './app/pages/project-page/rsee/rsee-import-form/RseeImportForm'
import { TeamPage } from './app/pages/project-page/team-page/team-page'
import { LayoutIcade } from './app/pages/report-icade/layout-icade'
import { ReportIcade } from './app/pages/report-icade/report-icade'
import { LayoutIlotLabro } from './app/pages/report-ilot-labro/layout-ilot-labro'
import { ReportIlotLabroR4 } from './app/pages/report-ilot-labro/report-ilot-labro-r4'
import { ReportIlotLabroR7 } from './app/pages/report-ilot-labro/report-ilot-labro-r7'
import { LayoutSequens } from './app/pages/report-sequens/layout-sequens'
import { ReportSequens } from './app/pages/report-sequens/report-sequens'
import SupportPage from './app/pages/support/support-page'
import { userMenu } from './app/pages/user-menu'
import { BSRoutes } from './BSRouter'
import { RseeRoutes } from './RseeRouter'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <ProjectTypeContextProvider>
          <Outlet />
        </ProjectTypeContextProvider>
      }>
      {/* Not authenticated user */}
      <Route path={pagesUrl.EXTENSION_BIM_VIEWER} element={<BimModelExtensionPage />} />
      <Route path={pagesUrl.KAIRNIAL_DASHBOARD} element={<KairnialDashboardPage />} />
      <Route
        element={
          <RequireNoAuth>
            <Outlet />
          </RequireNoAuth>
        }>
        <Route element={<LayoutIcade />}>
          <Route path={pagesUrl.ICADE_REPORT} element={<ReportIcade />} />
        </Route>
        <Route element={<LayoutSequens />}>
          <Route path={pagesUrl.SEQUENS_REPORT} element={<ReportSequens />} />
        </Route>
        <Route element={<LayoutIlotLabro />}>
          <Route path={pagesUrl.ILOT_LABRO_R4_REPORT} element={<ReportIlotLabroR4 />} />
          <Route path={pagesUrl.ILOT_LABRO_R7_REPORT} element={<ReportIlotLabroR7 />} />
        </Route>
        <Route path='/' element={<SignInPage />} />
        <Route path={pagesUrl.SIGN_IN_PAGE} element={<SignInPage />} />
        <Route path={pagesUrl.SIGN_UP_INVITATION_PAGE} element={<SignUpPage />} />
        <Route
          path={pagesUrl.SIGN_UP_PAGE}
          element={
            <GoogleReCaptchaContextProvider reCaptchaKey={getEnvProperty(PROPERTIES.REACT_APP_RECAPTCHA_SITE_KEY)}>
              <DomainNameValidatorContextProvider>
                <SignUpTestUserPage />
              </DomainNameValidatorContextProvider>
            </GoogleReCaptchaContextProvider>
          }
        />
        <Route path={pagesUrl.FORGOT_PWD_PAGE} element={<ForgotPasswordPage />} />
        <Route path={pagesUrl.CONFIRM_PAGE} element={<ConfirmPage />} />
        <Route path={pagesUrl.BEEM_GUIDE_EXCEL} element={<BeemGuideExcelPage />} />
        <Route path={pagesUrl.BEEM_GUIDE_EXCEL_ACCEPT} element={<BeemGuideExcelAcceptPage />} />
      </Route>
      {/* End not authenticated user */}

      {/* Contact support */}
      <Route
        path={pagesUrl.SUPPORT_PAGE}
        element={
          <DomainNameValidatorContextProvider>
            <SupportPage />
          </DomainNameValidatorContextProvider>
        }
      />
      {/* End Contact support */}

      {/* User part */}
      <Route
        element={
          <RequireAuth>
            <Layout mainMenu={userMenu} />
          </RequireAuth>
        }>
        {/* User account */}
        <Route path={pagesUrl.ACCOUNT_PAGE} element={<AccountPage />} />
        {/* End user account */}
        {/* Organization global control panel page */}
        <Route path={pagesUrl.GLOBAL_CONTROL_PANEL_DASHBOARD} element={<OrganizationDashboardPage />} />
        {/* End organization global control panel page */}
        {/* My organization */}
        <Route path={pagesUrl.MY_ORGANIZATION_PAGE} element={<MyOrganization />} />
        <Route
          path={pagesUrl.MY_ORGANIZATION_INVITE_USER_PAGE}
          element={
            <DomainNameValidatorContextProvider>
              <UserInvitationPage />
            </DomainNameValidatorContextProvider>
          }
        />
        <Route
          path={pagesUrl.MY_ORGANIZATION_EDIT_USER_PAGE}
          element={
            <DomainNameValidatorContextProvider>
              <UserUpdatePage />
            </DomainNameValidatorContextProvider>
          }
        />
        {/* End my organization */}
        {/* Material lib */}
        <Route
          path={pagesUrl.MY_MATERIAL_PAGE}
          element={
            <MaterialLibFilterContextProvider>
              <MaterialLibSearchContextProvider>
                <MaterialLibContextProvider>
                  <IniesRecordContextProvider>
                    <MaterialLibOrganizationIndicatorContextProvider>
                      <MaterialLibProjectIndicatorContextProvider>
                        <MaterialLibDeclarationTypeContextProvider>
                          <OrganizationMaterialLibPage />
                        </MaterialLibDeclarationTypeContextProvider>
                      </MaterialLibProjectIndicatorContextProvider>
                    </MaterialLibOrganizationIndicatorContextProvider>
                  </IniesRecordContextProvider>
                </MaterialLibContextProvider>
              </MaterialLibSearchContextProvider>
            </MaterialLibFilterContextProvider>
          }
        />
        {/* End material lib */}

        {/* RSEE project */}
        <Route path={pagesUrl.RSEE_PROJECT_NEW} element={<RseeImportForm />} />
        <Route path={pagesUrl.RSEE_PROJECT_DOCUMENT_SUMMARY_IMPORT} element={<RseeDocumentImportLog />} />
        <Route
          path={pagesUrl.RSEE_PROJECT}
          element={
            <RseeProjectContextProvider>
              <RseeProjectRoleContextProvider>
                <Outlet />
              </RseeProjectRoleContextProvider>
            </RseeProjectContextProvider>
          }>
          <Route path={pagesUrl.RSEE_PROJECT} element={<RseeDocumentListPage />} />
          <Route path={pagesUrl.RSEE_PROJECT_GROUP_UPDATE} element={<RseeGroupPage />} />
          <Route path={pagesUrl.RSEE_PROJECT_DOCUMENT_NEW} element={<RseeImportForm />} />
          <Route
            path={pagesUrl.RSEE_PROJECT_DOCUMENT}
            element={
              <RseeDocumentContextProvider>
                <Outlet />
              </RseeDocumentContextProvider>
            }>
            <Route path={pagesUrl.RSEE_PROJECT_DOCUMENT} element={<RseeDocumentDashboard />} />
            <Route path={pagesUrl.RSEE_PROJECT_DOCUMENT_UPDATE} element={<RseeImportForm />} />
            <Route path={pagesUrl.RSEE_PROJECT_DOCUMENT_GROUP_UPDATE} element={<RseeGroupPage />} />
          </Route>
        </Route>
        {/* End rsee project*/}
        <Route path={pagesUrl.PROJECTS_PAGE} element={<ProjectHomePage />} />
        <Route
          path={`${pagesUrl.PROJECT_PAGE}/:projectId`}
          element={
            <LotContextProvider>
              <ProjectContextProvider>
                <ProjectRoleContextProvider>
                  <SelectedPhaseProvider>
                    <BimModelContextProvider>
                      <BimModelFileContextProvider>
                        <TutorialEnabledContextProvider>
                          <CalculationContextProvider>
                            <VariantContextProvider>
                              <Outlet />
                            </VariantContextProvider>
                          </CalculationContextProvider>
                        </TutorialEnabledContextProvider>
                      </BimModelFileContextProvider>
                    </BimModelContextProvider>
                  </SelectedPhaseProvider>
                </ProjectRoleContextProvider>
              </ProjectContextProvider>
            </LotContextProvider>
          }>
          {/* fullscreen */}
          <Route
            path={`${pagesUrl.PROJECT_PAGE}/:projectId/fullscreen/:fullScreenToken`}
            element={
              <CodesExtraitsListContextProvider>
                <CodesCompletionsContextProvider>
                  <CalculationCarbonMaterialFilterContextProvider>
                    <CodeAcvCompletionContextProvider>
                      <CodeCustomContextProvider>
                        <CalculationCarbonExtractedDataProvider>
                          <CalculationCarbonAdditionalDataProvider>
                            <MaterialLibFilterContextProvider>
                              <MaterialLibSearchContextProvider>
                                <MaterialLibContextProvider>
                                  <IniesRecordContextProvider>
                                    <ProjectMaterialContextProvider>
                                      <FullScreenViewer />
                                    </ProjectMaterialContextProvider>
                                  </IniesRecordContextProvider>
                                </MaterialLibContextProvider>
                              </MaterialLibSearchContextProvider>
                            </MaterialLibFilterContextProvider>
                          </CalculationCarbonAdditionalDataProvider>
                        </CalculationCarbonExtractedDataProvider>
                      </CodeCustomContextProvider>
                    </CodeAcvCompletionContextProvider>
                  </CalculationCarbonMaterialFilterContextProvider>
                </CodesCompletionsContextProvider>
              </CodesExtraitsListContextProvider>
            }
          />
          {/* Control Board */}
          <Route path={pagesUrl.PROJECT_CONTROL_BOARD} element={<ProjectControlBoard />} />
          {/*End Control Board*/}

          {/* project infos */}
          <Route path={`${pagesUrl.PROJECT_CONTROL_BOARD}${pagesUrl.PROJECT_INFO}`} element={<ProjectForm />} />

          {/* create project*/}
          <Route path={`${pagesUrl.PROJECT_PAGE}/:projectId${pagesUrl.PROJECT_INFO}`} element={<ProjectForm />} />
          {/* End project infos */}

          {/* project team infos */}
          <Route path={pagesUrl.PROJECT_TEAM_MANAGEMENT} element={<TeamPage />} />
          <Route path={pagesUrl.PROJECT_TEAM_CREATION} element={<TeamPage />} />
          {/* End project team infos */}
          {/* project material lib */}
          <Route
            path={pagesUrl.PROJECT_MATERIAL_PAGE}
            element={
              <MaterialLibFilterContextProvider>
                <MaterialLibSearchContextProvider>
                  <MaterialLibContextProvider>
                    <IniesRecordContextProvider>
                      <MaterialLibOrganizationIndicatorContextProvider>
                        <MaterialLibProjectIndicatorContextProvider>
                          <MaterialLibDeclarationTypeContextProvider>
                            <ProjectMaterialLibPage />
                          </MaterialLibDeclarationTypeContextProvider>
                        </MaterialLibProjectIndicatorContextProvider>
                      </MaterialLibOrganizationIndicatorContextProvider>
                    </IniesRecordContextProvider>
                  </MaterialLibContextProvider>
                </MaterialLibSearchContextProvider>
              </MaterialLibFilterContextProvider>
            }
          />
          {/* End project material lib */}
          {/* project phase stepper */}
          <Route path={pagesUrl.PROJECT_PHASE_STEPPER} element={<ProjectPhaseStepper />} />
          <Route path={pagesUrl.PROJECT_PHASE_STEPPER_CREATION} element={<ProjectPhaseStepper />} />
          {/* End project phase stepper */}
          {/* bim model */}
          <Route
            path={`${pagesUrl.PROJECT_PAGE}/:projectId/bim-model`}
            element={
              <BimModelImportContextProvider>
                <Outlet />
              </BimModelImportContextProvider>
            }>
            <Route path={pagesUrl.PROJECT_BIM_MODEL_IMPORT_TUTO} element={<TutorialPage />} />
            <Route path={pagesUrl.PROJECT_BIM_MODEL_IMPORT} element={<BimModelUploadPage />} />
          </Route>
          {/* End bim model */}
          {/* Calculation */}
          <Route
            path={`${pagesUrl.PROJECT_PAGE}/:projectId/${pagesUrl.PROJECT_CALCULATION}`}
            element={
              <CodesExtraitsListContextProvider>
                <CodesCompletionsContextProvider>
                  <CalculationCarbonMaterialFilterContextProvider>
                    <CodeAcvCompletionContextProvider>
                      <CodeCustomContextProvider>
                        <CalculationCarbonExtractedDataProvider>
                          <CalculationCarbonAdditionalDataProvider>
                            <MaterialLibFilterContextProvider>
                              <MaterialLibSearchContextProvider>
                                <MaterialLibContextProvider>
                                  <IniesRecordContextProvider>
                                    <ProjectMaterialContextProvider>
                                      <Outlet />
                                    </ProjectMaterialContextProvider>
                                  </IniesRecordContextProvider>
                                </MaterialLibContextProvider>
                              </MaterialLibSearchContextProvider>
                            </MaterialLibFilterContextProvider>
                          </CalculationCarbonAdditionalDataProvider>
                        </CalculationCarbonExtractedDataProvider>
                      </CodeCustomContextProvider>
                    </CodeAcvCompletionContextProvider>
                  </CalculationCarbonMaterialFilterContextProvider>
                </CodesCompletionsContextProvider>
              </CodesExtraitsListContextProvider>
            }>
            <Route
              path={`${pagesUrl.PROJECT_PAGE}/:projectId/${pagesUrl.PROJECT_CALCULATION}`}
              element={
                <CalculationCarbonInformationProjectContextProvider>
                  <GeneralInformationPage />
                </CalculationCarbonInformationProjectContextProvider>
              }
            />
            <Route
              path={`${pagesUrl.PROJECT_PAGE}/:projectId/${pagesUrl.PROJECT_CALCULATION}/flate-rate-data`}
              element={
                <CalculationCarbonFlateRateProvider>
                  <FlateRatesPage />
                </CalculationCarbonFlateRateProvider>
              }
            />
            <Route
              path={`${pagesUrl.PROJECT_PAGE}/:projectId/${pagesUrl.PROJECT_CALCULATION}/extracted-data`}
              element={<ExtractedCodePage />}
            />
            <Route
              path={`${pagesUrl.PROJECT_PAGE}/:projectId/${pagesUrl.PROJECT_CALCULATION}/additional-data`}
              element={<AdditionalCodePage />}
            />
            <Route
              path={`${pagesUrl.PROJECT_PAGE}/:projectId/${pagesUrl.PROJECT_CALCULATION}/worksite`}
              element={<WorksitePage />}
            />
          </Route>
          {/* End Calculation */}
          {/* Dashboard */}
          <Route
            path={pagesUrl.PROJECT_DASHBOARD}
            element={
              <CodesExtraitsListContextProvider>
                <Outlet />
              </CodesExtraitsListContextProvider>
            }>
            <Route path={pagesUrl.PROJECT_DASHBOARD} element={<DashboardsPage />} />
          </Route>
          {/* End Dashboard */}
        </Route>
      </Route>

      {RseeRoutes}
      {/* End user part */}

      {/* BEEM Shot part */}
      {BSRoutes}
      {/* End BEEM Shot part */}

      {/* Admin/Ultimate user part*/}
      <Route
        path={adminPagesUrl.ADMIN}
        element={
          <RequireAuthUltimate>
            <OrganizationsListContextProvider>
              <AdminOrganizationContextProvider>
                <DomainNameValidatorContextProvider>
                  <Layout mainMenu={adminMenu} />
                </DomainNameValidatorContextProvider>
              </AdminOrganizationContextProvider>
            </OrganizationsListContextProvider>
          </RequireAuthUltimate>
        }>
        <Route path={adminPagesUrl.ADMIN_ORGANIZATION_LIST_PAGE} element={<OrganizationList />} />
        <Route path={adminPagesUrl.ADMIN_ORGANIZATION_USER_PAGE} element={<AdminUpdateUserForm />} />
        <Route path={adminPagesUrl.ADMIN_INVITED_USERS_LIST_PAGE} element={<TestUsersList />} />
        <Route path={adminPagesUrl.ADMIN_SUMMARY} element={<AdminStatistics />} />

        <Route
          element={
            <RequireAuthUltimate>
              <AdminOrganizationContextProvider>
                <LayoutAdmin />
              </AdminOrganizationContextProvider>
            </RequireAuthUltimate>
          }>
          {/*Detail of an organization  */}
          <Route path={adminPagesUrl.ADMIN_ORGANIZATION_PAGE} element={<OrganizationPage />} />

          {/*Detail of the users in a organization    */}
          <Route
            path={adminPagesUrl.ADMIN_USER_ORGANIZATION_PAGE}
            element={
              <DomainNameValidatorContextProvider>
                <UserTableOrganizationPage />
              </DomainNameValidatorContextProvider>
            }
          />

          {/* Material lib */}
          <Route
            path={adminPagesUrl.MY_MATERIAL_PAGE}
            element={
              <MaterialLibFilterContextProvider>
                <MaterialLibSearchContextProvider>
                  <MaterialLibContextProvider>
                    <IniesRecordContextProvider>
                      <MaterialLibOrganizationIndicatorContextProvider>
                        <MaterialLibProjectIndicatorContextProvider>
                          <MaterialLibDeclarationTypeContextProvider>
                            <OrganizationMaterialLibPage />
                          </MaterialLibDeclarationTypeContextProvider>
                        </MaterialLibProjectIndicatorContextProvider>
                      </MaterialLibOrganizationIndicatorContextProvider>
                    </IniesRecordContextProvider>
                  </MaterialLibContextProvider>
                </MaterialLibSearchContextProvider>
              </MaterialLibFilterContextProvider>
            }
          />
          {/* End material lib */}

          {/* RSEE project */}
          <Route path={adminPagesUrl.PROJECT_RSEE_PAGE} element={<RseeProjectOrganizationPage />} />

          <Route
            path={adminPagesUrl.RSEE_PROJECT}
            element={
              <RseeProjectContextProvider>
                <Outlet />
              </RseeProjectContextProvider>
            }>
            <Route path={adminPagesUrl.RSEE_PROJECT} element={<RseeDocumentListPage />} />
            <Route path={adminPagesUrl.RSEE_PROJECT_GROUP_UPDATE} element={<RseeGroupPage />} />
            <Route
              path={adminPagesUrl.RSEE_PROJECT_DOCUMENT}
              element={
                <RseeDocumentContextProvider>
                  <Outlet />
                </RseeDocumentContextProvider>
              }>
              <Route path={adminPagesUrl.RSEE_PROJECT_DOCUMENT} element={<RseeDocumentDashboard />} />
              <Route path={adminPagesUrl.RSEE_PROJECT_DOCUMENT_GROUP_UPDATE} element={<RseeGroupPage />} />
            </Route>
          </Route>
          {/* End rsee project*/}

          <Route
            path={`${adminPagesUrl.ADMIN_ORGANIZATION_INVITE_USER_PAGE}`}
            element={
              <RequireOrganization>
                <DomainNameValidatorContextProvider>
                  <AdminInviteUserForm />
                </DomainNameValidatorContextProvider>
              </RequireOrganization>
            }
          />
          <Route path={`${adminPagesUrl.ADMIN_ORGANIZATION_INVITE_FIRST_MEGA_USER}`} element={<AdminInviteUserForm />} />
          <Route
            path={`${adminPagesUrl.ADMIN_ORGANIZATION_INVITE_FIRST_MEGA_USER}`}
            element={
              <RequireOrganization>
                <DomainNameValidatorContextProvider>
                  <AdminInviteUserForm />
                </DomainNameValidatorContextProvider>
              </RequireOrganization>
            }
          />

          {/* Management BIM Project */}
          <Route path={adminPagesUrl.BIM_PROJECT_PAGE} element={<BimProjectOrganizationPage />} />
          <Route
            path={`${adminPagesUrl.BIM_PROJECT_PAGE}/:projectId`}
            element={
              <LotContextProvider>
                <ProjectContextProvider>
                  <ProjectRoleContextProvider>
                    <SelectedPhaseProvider>
                      <BimModelContextProvider>
                        <BimModelFileContextProvider>
                          <TutorialEnabledContextProvider>
                            <CalculationContextProvider>
                              <VariantContextProvider>
                                <Outlet />
                              </VariantContextProvider>
                            </CalculationContextProvider>
                          </TutorialEnabledContextProvider>
                        </BimModelFileContextProvider>
                      </BimModelContextProvider>
                    </SelectedPhaseProvider>
                  </ProjectRoleContextProvider>
                </ProjectContextProvider>
              </LotContextProvider>
            }>
            <Route path={adminPagesUrl.PROJECT_CONTROL_BOARD} element={<ProjectControlBoard />} />
            <Route path={adminPagesUrl.PROJECT_INFO} element={<ProjectForm />} />
            <Route path={adminPagesUrl.PROJECT_TEAM_MANAGEMENT} element={<TeamPage />} />

            {/* project material lib */}
            <Route
              path={adminPagesUrl.PROJECT_MATERIAL_PAGE}
              element={
                <MaterialLibFilterContextProvider>
                  <MaterialLibSearchContextProvider>
                    <MaterialLibContextProvider>
                      <IniesRecordContextProvider>
                        <MaterialLibOrganizationIndicatorContextProvider>
                          <MaterialLibProjectIndicatorContextProvider>
                            <MaterialLibDeclarationTypeContextProvider>
                              <ProjectMaterialLibPage />
                            </MaterialLibDeclarationTypeContextProvider>
                          </MaterialLibProjectIndicatorContextProvider>
                        </MaterialLibOrganizationIndicatorContextProvider>
                      </IniesRecordContextProvider>
                    </MaterialLibContextProvider>
                  </MaterialLibSearchContextProvider>
                </MaterialLibFilterContextProvider>
              }
            />
            {/* End project material lib */}
            {/* bim model */}
            <Route
              path={`${adminPagesUrl.BIM_PROJECT_PAGE}/:projectId/bim-model`}
              element={
                <BimModelImportContextProvider>
                  <Outlet />
                </BimModelImportContextProvider>
              }>
              <Route path={adminPagesUrl.ADMIN_PROJECT_BIM_MODEL_IMPORT} element={<BimModelUploadPage />} />
            </Route>
            {/* End bim model */}

            {/* Calculation */}
            <Route
              path={adminPagesUrl.PROJECT_CALCULATION_CORE_DATA}
              element={
                <CodesExtraitsListContextProvider>
                  <CodesCompletionsContextProvider>
                    <CalculationCarbonMaterialFilterContextProvider>
                      <CodeAcvCompletionContextProvider>
                        <CodeCustomContextProvider>
                          <CalculationCarbonExtractedDataProvider>
                            <CalculationCarbonAdditionalDataProvider>
                              <MaterialLibFilterContextProvider>
                                <MaterialLibSearchContextProvider>
                                  <MaterialLibContextProvider>
                                    <IniesRecordContextProvider>
                                      <ProjectMaterialContextProvider>
                                        <Outlet />
                                      </ProjectMaterialContextProvider>
                                    </IniesRecordContextProvider>
                                  </MaterialLibContextProvider>
                                </MaterialLibSearchContextProvider>
                              </MaterialLibFilterContextProvider>
                            </CalculationCarbonAdditionalDataProvider>
                          </CalculationCarbonExtractedDataProvider>
                        </CodeCustomContextProvider>
                      </CodeAcvCompletionContextProvider>
                    </CalculationCarbonMaterialFilterContextProvider>
                  </CodesCompletionsContextProvider>
                </CodesExtraitsListContextProvider>
              }>
              <Route
                path={adminPagesUrl.PROJECT_CALCULATION_CORE_DATA}
                element={
                  <CalculationCarbonInformationProjectContextProvider>
                    <GeneralInformationPage />
                  </CalculationCarbonInformationProjectContextProvider>
                }
              />
              <Route
                path={adminPagesUrl.PROJECT_CALCULATION_FLAT_RATES}
                element={
                  <CalculationCarbonFlateRateProvider>
                    <FlateRatesPage />
                  </CalculationCarbonFlateRateProvider>
                }
              />
              <Route path={adminPagesUrl.PROJECT_CALCULATION_EXTRACTED_DATA} element={<ExtractedCodePage />} />
              <Route path={adminPagesUrl.PROJECT_CALCULATION_ADDITIONAL_DATA} element={<AdditionalCodePage />} />
              <Route path={adminPagesUrl.PROJECT_CALCULATION_WORKSITE} element={<WorksitePage />} />
            </Route>
            {/* End Calculation */}

            {/* Dashboard */}
            <Route
              path={adminPagesUrl.ADMIN_PROJECT_DASHBOARD}
              element={
                <CodesExtraitsListContextProvider>
                  <Outlet />
                </CodesExtraitsListContextProvider>
              }>
              <Route path={adminPagesUrl.ADMIN_PROJECT_DASHBOARD_TABLEAU} element={<DashboardsPage />} />
            </Route>
          </Route>
          {/* End Dashboard */}
        </Route>
      </Route>
      {/* End admin/Ultimate user part*/}
      <Route path='*' element={<NoMatch />} />
    </Route>
  )
)

export default function AppRouter(): React.JSX.Element {
  return <RouterProvider router={router} />
}
